import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { QuoteMutationTypes } from '@/core/enum/Quotes/Mutations';
import { QuoteActionTypes } from '@/core/enum/Quotes/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';
import { isCalled } from '@/store/stateless';
import { compare_quote_payload } from '@/core/types';
import router from '@/router';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [QuoteActionTypes.GET_QUOTES](
    { commit }: AugmentedActionContext,
    payload:compare_quote_payload
  );

  [QuoteActionTypes.PROCEED_BUY](
    { commit }: AugmentedActionContext,
    payload:any
  );
  
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [QuoteActionTypes.GET_QUOTES]({ commit },payload) {
    try {
      // if (isCalled.getQuotes) return;
      // // const payload = {
      // //   lang: window.localStorage.getItem('language'),
      // //   isDropdown: true
      // // }
      // console.log("in api",payload);
      const data = await axios.post(Links.GET_QUOTES, payload);
      commit(QuoteMutationTypes.SET_QUOTES, data.data.data.policies);
      commit(QuoteMutationTypes.SET_INFORMATIONS, data.data.data.informations);
    }
    catch (err) {
      return err;
    }
  },

  async [QuoteActionTypes.PROCEED_BUY]({ commit },payload) {

    const data = await axios.post(Links.PROCEED_BUY, payload);
    console.log('bnr',data);
    window.localStorage.setItem('interested_policy',JSON.stringify(data.data.data.policy));
    window.localStorage.setItem('selected_premium',JSON.stringify(data.data.data.selected_premium));
    window.localStorage.setItem('basmah',data.data.data.basmah);
    // commit(QuoteMutationTypes.SET_INTERESTED_POLICY, data.data.data);

  },

  async [QuoteActionTypes.GET_VAT]({ commit }) {

    const data = await axios.post(Links.GET_VAT);
    console.log('gvat',data);
    commit(QuoteMutationTypes.SET_VAT, data.data.data.vat);
  },

  async [QuoteActionTypes.GET_BENEFIT_MASTER]({ commit }) {

    const data = await axios.post(Links.GET_BENEFIT_MASTER);
    commit(QuoteMutationTypes.SET_BENEFIT_MASTER, data.data.data);
  },


  async [QuoteActionTypes.PROCEED_CHECKOUT]({ commit },payload) {

    const data = await axios.post(Links.PROCEED_CHECKOUT, payload);
    console.log('CHCEKOUT',data);
    const source = window.localStorage.getItem('sources')
    const path = source?.slice(1)

    

    window.location.href = process.env.VUE_APP_PAYMENT_GATEWAY_URL + '/' + payload.language + '/health/payments/health/' + data.data.data.merchant_reference + '/db-deep-chr' + '?' + path
    setTimeout(() => {
      isCalled.mainLoader = false
    },4000);

  },
};
