export enum PersonalMutationTypes {
    SET_CUSTOMER = 'SET_CUSTOMER',
    SET_CUSTOMER_AGES = 'SET_CUSTOMER_AGES',
    SET_CUSTOMER_MEMBERS = 'SET_CUSTOMER_MEMBERS',
    SET_CUSTOMER_RESULT = 'SET_CUSTOMER_RESULT',
    SET_CUSTOMER_LOADING = 'SET_CUSTOMER_LOADING',
    SET_CUSTOMER_EXISTING_LEADS = 'SET_CUSTOMER_EXISTING_LEADS',
    SET_CUSTOMER_EXISTING_SELECTED_LEADS = 'SET_CUSTOMER_EXISTING_SELECTED_LEADS',
    SET_INSURED_TYPE_DISABLED = 'SET_INSURED_TYPE_DISABLED',
    SET_EXIST_CLICK = 'SET_EXIST_CLICK',

    
  }
  