import { store } from "@/store";
import { App } from "vue";
import { QuoteActionTypes } from "@/core/enum/Quotes/Actions";

import {compare_quote_payload} from  '@/core/types';
class Quotes{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        Quotes.vueInstance = app;
    }

    public static async getQuotes(payload:compare_quote_payload) {
        return await store
        .dispatch(QuoteActionTypes.GET_QUOTES,payload)
    }

    public static async buyNow(payload:any) {
        return await store
        .dispatch(QuoteActionTypes.PROCEED_BUY,payload)
    }

    public static async getVat() {
        return await store.dispatch(QuoteActionTypes.GET_VAT)
    }

    public static async getBenefitMaster() {
        return await store.dispatch(QuoteActionTypes.GET_BENEFIT_MASTER)
    }

    public static async proceedCheckout(payload:any) {
        return await store
        .dispatch(QuoteActionTypes.PROCEED_CHECKOUT,payload)
    }

}

export default Quotes;