import { GetterTree } from 'vuex';


import { RootState } from '@/store';

import { Health } from '@/core/types';

import { State } from './state';

export type Getters = {
  getCustomer(state: State): Health;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getCustomer: (state) => state.customer,

  getAllExistingLeads: (state) => {
    if(state.existingLeads.length > 0) {
      return state.existingLeads.map(x => {
        const count = x.insurance_type == 1 ? x.leads.length : x.leads[0]?.lead_informations.length ?? 1;
        return {
          map_lead_id: x.id,
          customer_id: x.customer_id,
          is_declaration_submitted: x.is_declaration_submitted === 1 ? true : false,
          name: `<span style="color: #222261; font-weight: bold">Health</span> - ${getInsuranceFor(x.insured_type_id)} - ${count} ${count > 1 ? 'Members' : 'Member'}`
        }
      })
    }
    return []
  }

};


const getInsuranceFor = (insured_type_id) => {
  switch(insured_type_id) {
    case 1: return 'First';
    case 2: return 'Second';
    case 3: return 'Third';
    case 4: return 'Lux';
    default: return '';
  }
}