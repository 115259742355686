import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "traveller_color_seq" }
const _hoisted_3 = { class: "vechile-details-list" }
const _hoisted_4 = ["element-loading-spinner"]
const _hoisted_5 = { class: "row form-block travel-date" }
const _hoisted_6 = { class: "col-md-4" }
const _hoisted_7 = { class: "col-md-4" }
const _hoisted_8 = { class: "col-md-4" }
const _hoisted_9 = { class: "row form-block travel-date" }
const _hoisted_10 = { class: "col-md-4" }
const _hoisted_11 = { class: "d-flex align-items-center justify-content-center btn-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_input = _resolveComponent("h-input")!
  const _component_h_date_v2 = _resolveComponent("h-date-v2")!
  const _component_h_nationality = _resolveComponent("h-nationality")!
  const _component_h_gender = _resolveComponent("h-gender")!
  const _component_h_back = _resolveComponent("h-back")!
  const _component_h_proceed = _resolveComponent("h-proceed")!
  const _component_el_form = _resolveComponent("el-form")!
  const _directive_loading = _resolveDirective("loading")!

  return (_ctx.item.is_editable)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("member_dob_label", { count: _ctx.counting + 1 })), 1),
        _createVNode(_component_el_form, {
          ref: "formReviewMember",
          model: _ctx.item,
          "status-icon": "",
          rules: _ctx.rules,
          class: "demo-ruleForm"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives((_openBlock(), _createElementBlock("div", {
                "element-loading-spinner": _ctx.svgLoading,
                "element-loading-svg-view-box": "-10, -10, 50, 50",
                "element-loading-background": "rgba(122, 122, 122, 0.3)"
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_h_input, {
                      isRequired: "",
                      label: _ctx.$t('member_full_name'),
                      isType: "text",
                      "form-prop": "member_name",
                      modelValue: _ctx.item.member_name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.member_name) = $event)),
                      "max-length": "62",
                      onkeydown: _ctx.language === 'en' ? `return /[a-zA-Z_ \s'-]/i.test(event.key)` : '',
                      ref: "member_name",
                      placeHolder: _ctx.$t('member_full_name')
                    }, null, 8, ["label", "modelValue", "onkeydown", "placeHolder"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_h_date_v2, {
                      isRequired: "",
                      "model-value": _ctx.item.dob,
                      "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.dob) = $event)),
                      ageValue: _ctx.item.age,
                      "onUpdate:ageValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.age) = $event)),
                      "form-prop": "dob",
                      defaultValue: _ctx.defaultDob,
                      label: _ctx.$t('date_of_birth'),
                      ref: "dob",
                      disabled: ""
                    }, null, 8, ["model-value", "ageValue", "defaultValue", "label"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_h_nationality, {
                      isRequired: "",
                      label: _ctx.$t('nationality'),
                      modelValue: _ctx.item.nationality_id,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.nationality_id) = $event)),
                      formProp: "nationality_id",
                      ref: "nationality_id"
                    }, null, 8, ["label", "modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_h_gender, {
                      isRequired: "",
                      label: _ctx.$t('gender'),
                      formProp: "gender",
                      modelValue: _ctx.item.gender,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.gender) = $event)),
                      ref: "gender"
                    }, null, 8, ["label", "modelValue"])
                  ])
                ])
              ], 8, _hoisted_4)), [
                [_directive_loading, _ctx.loading]
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_h_back, {
                  onHandleClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.previousTraveller(_ctx.counting))),
                  disabled: _ctx.counting == 0
                }, null, 8, ["disabled"]),
                _createVNode(_component_h_proceed, {
                  onHandleClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.reviewDetails.click(_ctx.formReviewMember))),
                  disabled: _ctx.loading
                }, null, 8, ["disabled"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]))
    : _createCommentVNode("", true)
}