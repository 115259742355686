
import { defineComponent, onMounted, getCurrentInstance, computed, provide } from "vue";
import Language from "@/core/services/Language";
// import Declarations from '@/core/services/Declarations';
import PersonalDetails from '@/core/services/PersonalDetails';
import router from "./router";
import { Information } from '@/core/types';
import { store } from "@/store";
import { isCalled } from '@/store/stateless';

export default defineComponent({
  
  setup() {
    const clang = computed(() => {
            return store.state.lang.lang
    })

    const customer = computed(() => {
      // console.log('sddsfsfd',store.state.personals.customer)
      return store.state.personals.customer;
    });

    const host = window.location.host;
    const domain = process.env.VUE_APP_HEALTH_DOMAIN;

    console.log('xxxx')
    console.log(host)
    console.log(domain)

    const currentInstance = getCurrentInstance();
    const dates = currentInstance?.appContext.config.globalProperties.dates;
    onMounted(async () => {
      
      const sid = router.currentRoute.value.query && router.currentRoute.value.query.sid ?  router.currentRoute.value.query.sid.toString() : '';
      if(sid != ''){
        // window.localStorage.clear()
      }
      


      

      let name = router.currentRoute.value.query?.name ?  router.currentRoute.value.query.name.toString() : '';
      let email = router.currentRoute.value.query?.email ?  router.currentRoute.value.query.email.toString() : '';
      let pn = router.currentRoute.value.query?.pn ?  router.currentRoute.value.query.pn.toString() : '';
      customer.value.phone_country_code = router.currentRoute.value.query?.country_code ?  router.currentRoute.value.query.country_code.toString() : '+961';

      if(name != '' && email != '' && pn != ''){
        // setting value from query coming from get Quote from FE
        // console.log('upasdjsdjshfd')
        customer.value.name = name;
        customer.value.email = email;
        customer.value.phone_number = pn;
        if(!customer.value.phone_country_code.includes('+')){
          customer.value.phone_country_code = '+' + customer.value.phone_country_code.trim();
        }
      }
      else {
        const pd = window.sessionStorage.getItem('pd');
        // console.log(pd);
        if (pd && sid == '') {
          console.log('pd', JSON.parse(pd));
          PersonalDetails.setCustomer(JSON.parse(pd));
          await PersonalDetails.getLatestLeads(JSON.parse(pd))
        }
      }
      
      
      window.localStorage.setItem('quotes_not_found','NO');
      
      const routeLanguage =
        router.currentRoute.value.query && router.currentRoute.value.query.lang
          ? router.currentRoute.value.query.lang.toString()
          : "";
      const language = window.localStorage.getItem("language");
      if (routeLanguage) {
        Language.setLanguage(routeLanguage);
      } else {
        if (!language) {
          Language.setLanguage("en");
        }
      }
      

      const personal_details = window.sessionStorage.getItem('personal_details');
      if (personal_details) {
        const personal = JSON.parse(personal_details);
        PersonalDetails.setResult(personal);

        const members = personal.lead_information.map((x: Information) => {
          return {
            value: x.id,
            label: x.member_name
          }
        })

        const ages = personal.lead_information.map((x: Information) => {
          return dates.getAge(x.dob);
        })

        // PersonalDetails.setMembers(members);
        // PersonalDetails.setAges(ages);
      }

      // await Declarations.getDeclarations();

    });


    const isMobile = window.innerWidth < 960
    provide('isMobile', isMobile)

    return {
      isCalled,
      clang,
      host,
      domain
    }
  },
});
