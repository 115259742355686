
/* eslint-disable */
import {
  defineComponent,
  reactive,
  ref,
  computed,
  watch,
  getCurrentInstance,
} from "vue";
import Validations from "@/core/validations";
import { FormInstance } from "element-plus";
import { Health, Member } from "@/core/types";
import { loading, svgLoading, focus, dates as dDate } from "@/store/stateless";
import moment from "moment";
import { store } from "@/store";

export default defineComponent({
  name: "reviewMemberFormComponent",
  props: {
    item: {
      type: Object,
      required: true,
      default: {} as Member,
    },
    counting: {
      type: Number,
      required: true,
      default: 1,
    },
    insurance: {
      type: Array,
      required: true,
      default: [],
    },
    review_info: {
      type: Object,
      required: true,
      default: {} as Health,
    },
    is_api_plan: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(_) {

    console.log('item',_.item)
    const formReviewMember = ref<FormInstance>();

    const language = computed(() => {
      return window.localStorage.getItem("language");
    });

    const defaultDob = computed(() => {
      return moment().subtract(30, "y").format("YYYY-MM-DD");
    });

    const previousTraveller = (count) => {
      console.log(count);
        loading.value = true
      // isCalled.mainLoader = true;
      setTimeout(() => {
        window.localStorage.setItem("member_index", JSON.stringify(count - 1));
        store.state.personals.customer.members[count].is_editable = false;
        if (store.state.personals.customer.members[count - 1] != undefined) {
          store.state.personals.customer.members[count - 1].is_editable = true;
        }
        // isCalled.mainLoader = false;
        loading.value = false

        // scrollTo({ top: 120 });
      }, 500);
      return "";
    };

   
    

    const rules = reactive({
      member_name: [ { validator: Validations.names, trigger: ["blur", "change"] }],
      dob: [{ validator: Validations.dob, trigger: ["blur", "change"] }],
      relationship: [{ validator: Validations.relationship, trigger: ["blur", "change"] }],
      gender: [{ validator: Validations.gender, trigger: ["blur", "change"] }],
      nationality_id: [{ validator: Validations.nationality, trigger: ["blur", "change"] }],
    });

    const inst = getCurrentInstance();
    watch(
      () => focus.review,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          const toFocus: any = inst?.refs[newValue];
          if (toFocus != undefined) toFocus.focus();
        }
      }
    );

    const allowAlphabetsOnly = ($event) =>
      language.value === "en" ? /[a-zA-Z\s' ]/i.test($event.key) : "";
    const allowAlphaNumeric = ($event) =>
      language.value === "en" ? /[a-zA-Z0-9' ]/i.test($event.key) : "";

    const updateGender = (member) => {
      if (
        member.relationship === "Son" ||
        member.relationship === "Brother" ||
        member.relationship === "Father" ||
        member.relationship === "Father-in-law"
      )
        member.gender = "MALE";
      else if (
        member.relationship === "ابن" ||
        member.relationship === "أخ" ||
        member.relationship === "أب" ||
        member.relationship === "حَمُو"
      )
        member.gender = "MALE";
      else if (
        member.relationship === "Daughter" ||
        member.relationship === "Sister" ||
        member.relationship === "Mother" ||
        member.relationship === "Mother-in-law"
      )
        member.gender = "FEMALE";
      else if (
        member.relationship === "ابنة" ||
        member.relationship === "أخت" ||
        member.relationship === "أم" ||
        member.relationship === "حَمَاة"
      )
        member.gender = "FEMALE";
      else member.gender = null;

      UpdateIsMarried(member);
    };


    const UpdateIsMarried = (member) => {
      member.is_married = false;
      if (member.gender === "MALE") member.is_married = false;

      if (member.relationship === "Spouse" && member.gender === "FEMALE") {
        member.is_married = true;
      } else member.is_married = false;
    };

    return {
      loading,
      svgLoading,
      focus,
      dDate,
      rules,
      defaultDob,
      language,
      previousTraveller,
      formReviewMember,
      allowAlphabetsOnly,
      allowAlphaNumeric,
      updateGender,
      UpdateIsMarried
    };
  },
});
