
  import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref, watchEffect } from "vue";
  import { formPersonalDetails } from "@/store/stateless";
  import { emirates, northern_emirate } from "@/core/utils";
  import { store } from "@/store";
  import Validations from "@/core/validations";
  import { focus, dates, thankYouPopup } from "@/store/stateless"
  import personalDetails from "@/core/services/PersonalDetails";
  import router from '@/router';

  export default defineComponent({
    name: 'otherComponent',
    // eslint-disable-next-line
    setup(_) {


      const lang = computed(() => {
        const lang = window.localStorage.getItem("language");
        if (lang)
          return lang;

        return 'en';
      });

      const disablePhone = computed(() => {
        let pn = router.currentRoute.value.query?.pn ? router.currentRoute.value.query.pn.toString() : '';
        if (pn != '')
          return true

        return false
      })

      const showMembers = ref(true);
      const salary = ref('4,000');
      const is_salary = ref(true);

      const customer = computed(() => {
        return store.state.personals.customer;
      });

      const loading = computed(() => {
        return store.state.personals.loading;
      });

      const rules = reactive({
        name: [{ validator: Validations.names, trigger: ["blur", "change"] }],
        phone_number: [
          { validator: Validations.phone_number, trigger: ["blur", "change"] },
        ],
        insured_type_id: [
          { validator: Validations.insured_type, trigger: ["blur", "change"] },
        ],
        expected_insurance_start_date: [
          { validator: Validations.insurance_date, trigger: ["blur", "change"] },
        ],
        email: [{ validator: Validations.email, trigger: ["blur", "change"] }],
      });

      onMounted(async () => {

        console.log('other',customer.value)

        if (customer.value.phone_number) await getLatestLeads();

        if (customer.value.members.length == 0)
          showMembers.value = false;

        const callBack = window.localStorage.getItem('callBack');

        if (callBack)
          showMembers.value = false

        if (focus.personal !== '') {
          // eslint-disable-next-line
          const toFocus: any = inst?.refs[focus.personal]
          if (toFocus !== undefined) {
            toFocus.focus()
          }
        }

        addNewMemberForm()

      })


      const memberForm = ref();

      const getExistLeads = async () => {
        // console.log('existing leads',customer.value)
        // customer.value.id = null
        // customer.value.health_lead_map_id = null
        // console.log('existing leads',customer.value)
        
        await getLatestLeads()
        await callLoad()
      }

      const callLoad = async () => {
        // console.log('cus',store.state.personals.customer)
        const customer = store.state.personals.customer;
        personalDetails.setExistClick(false);
        personalDetails.setCustomer({
          id: customer.id,
          lang: customer.lang,
          name: customer.name,
          email: customer.email ?? null,
          // contact_person: null,
          phone_number: customer.phone_number,
          phone_country_code: customer.phone_country_code,
          insured_city: customer.insured_city,
          insured_type_id: customer.insured_type_id,
          insurance_type: 1,
          is_salary: customer.is_salary,


          expected_insurance_start_date: null,
          expected_premium: null,

          expected_insurance_start_day: "",
          expected_insurance_start_month: "",
          expected_insurance_start_year: "",
          customer_source: null,
          is_health: 1,
          utm_source: null,
          utm_medium: null,
          utm_campaign: null,
          utm_content: null,
          age: 0,
          dob: null,
          dob_day: "",
          dob_month: "",
          dob_year: "",
          nationality_id: 0,
          gender: null,
          is_married: false,
          company_name: null,
          is_self: null,
          agent: null,
          health_lead_map_id: 0,
          is_company: false,
          family_size:1,
          members: [{
              id:  null,
              lead_id: null,
              age: 0,
              dob: null,
              is_temp: 0,
              temp_record: 0
          }],
          categories: [],
          query: {},
          is_web: true
        })
        formPersonalDetails.value?.resetFields()
        // console.log(memberForm.value)
        // if (memberForm.value) await memberForm.value.forEach(x => x.relationship?.load());
      };

      const inst = getCurrentInstance()


      watchEffect(async () => {
        // console.log('customer',customer.value)
        if(customer.value.family_size && customer.value.members.length && (customer.value.family_size != customer.value.members.length)) memeberAction();
      })

      const memeberAction = () => {
        if(customer.value.family_size == 1 && !customer.value.members.length) {
          addNewMemberForm();
        } else if((customer.value.family_size != customer.value.members.length) && (customer.value.family_size > customer.value.members.length)) {
          addNewMemberForm();
        } else if(customer.value.family_size < customer.value.members.length) {
          deleteMemberV(customer.value.members, (customer.value.members.length - 1))
        }
      }

      const addNewMemberForm = () => {
        showMembers.value = true;
        
        customer.value.members.push({
          id: null,
          lead_id: 0,
          member_name: null,
          relationship: null,
          gender: null,
          dob: null,
          age: 0,
          nationality_id: null,
          is_salary: false,
          // declaration_answers: [],
          is_married: false,
          is_editable: true,
          is_temp: 0,
          temp_record: 0
        })
      }

      const deleteMemberV = (members, index) => {
        const customer = store.state.personals.customer
        if(members[index].id != null) {
            customer.deleted_members.push(members[index])
        }
        members.splice(index, 1);
      }

      const getLatestLeads = async () => {
        return await personalDetails.getLatestLeads(customer.value)
      }

      const disabledInsuredType = computed(() => {
        return store.state.personals.insured_type_disabled
      })

      const requestCallBack = () => {
        window.location.href = '/thankyou'
      }


      const emailEvent = () => {
        Validations.email_taken = false
      }

      return {
        disabledInsuredType,
        focus,
        getExistLeads,
        callLoad,
        loading,
        formPersonalDetails,
        emirates,
        customer,
        rules,
        dates,
        memberForm,
        getLatestLeads,
        thankYouPopup,
        showMembers,
        requestCallBack,
        salary,
        northern_emirate,
        is_salary,
        emailEvent,
        lang,
        inst,
        disablePhone
      };
    },
  });
