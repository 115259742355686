/* eslint-disable */
import { App } from "vue";
import { FormInstance } from "element-plus";
import { store } from "@/store";
import { focus, loading } from "@/store/stateless"
import Validations from "@/core/validations";
import router from "@/router";
import pd from "@/core/services/PersonalDetails";

export const reviewDetails = (app: App<Element>) => {
    const dates = app.config.globalProperties.dates;
    app.config.globalProperties.reviewDetails = {
        async click(formEl: FormInstance) {
            focus.review = ''
            let current_indext:any = window.localStorage.getItem('member_index');

            if (!formEl) return;
            formEl.validate(async (valid, fields: any) => {
                if (valid) {
                    if(!loading.value) {
                        loading.value = true
                        let member_index = window.localStorage.getItem('member_index')
                        const member_id = member_index ? parseInt(member_index) : 0;
                        const member = store.state.personals.customer.members[member_id];
                        console.log('memerfsfsdf',member)
                        if(store.state.personals.customer.members[member_id + 1] != undefined) {
                            setTimeout(() => {
                                member.is_editable = false
                                store.state.personals.customer.members[member_id + 1].is_editable = true;
                                // isCalled.mainLoader = false
                                loading.value = false
                            }, 500);
                        }
                        const sessionId = window.sessionStorage.getItem('session_id');
                        const mapId = window.sessionStorage.getItem('map_id');
                        const payload = {
                            member: member,
                            session_id: sessionId ?? '',
                            map_id: mapId ?? ''
                        }
                        // await pd.setCustomer()
                        window.sessionStorage.setItem('pd', JSON.stringify(store.state.personals.customer));

                        const result = await pd.saveMemberDetails(payload);
                        if (result && result.status !== 200 && result.data) {
                            formEl.validate(async (valid, field: any) => {
                                focus.review = ''
                                // formEl.scrollToField(Object.keys(field)[0])
                                focus.review = Object.keys(field)[0]
                            })
                        }
                        window.localStorage.setItem('member_index', JSON.stringify((parseInt(current_indext) + 1)))
                        // isCalled.mainLoader = false

                        current_indext = window.localStorage.getItem('member_index');

                        if(current_indext  >= store.state.personals.customer.members.length){
                            setTimeout(() => {
                                loading.value = false
                            },500);

                            const route = router.resolve({ name: 'CheckoutDetails' });
                            window.location.href = route.href;
                        }
                    }
                } else {
                    focus.review = ''
                    const firstError = Object.keys(fields)[0]
                    formEl.scrollToField(`${firstError}_label`)
                    focus.review = Object.keys(fields)[0]
                    console.log({ focus })
                }
            })
        },
        reset(formEl: FormInstance | undefined) {
            if (!formEl) return
            formEl.clearValidate()
        }
    }
}