
import { defineComponent, computed } from "vue";
import { store } from "@/store";

export default defineComponent({
  setup() {

    const host = computed(() => {
            return (process.env.VUE_APP_FOLDER !== '') ? `/${process.env.VUE_APP_FOLDER}/personal-details` : `/personal-details`;
        });

    return {
      host
    };
  },
});
