export enum Links {
    /**
     * @description GET
     */
    GET_COUNTRIES = '/health/countries',
    GET_COUNTRIES_CODE = '/car/country-codes',
    GET_INSURED_TYPES = '/health/insured-types',
    GET_RELATIONSHIPS = '/health/relationships',
    GET_DECLARATIONS = '/health/declaration',
    GET_LATEST_LEADS_BY_INSURANCE_ID = '/health/get-latest-leads-by-insurance-type-phone-number',
    GET_VAT = '/health/get-vat',


    /**
     * @description POST
     */
    GET_PERSONAL_DETAILS = '/health/get-personal-details',
    POST_PERSONAL_DETAILS = '/health/add-health-lead',
    POST_HEALTH_DECLARATIONS = '/health/update-declaration-answers',
    GET_QUOTES = '/health/compare-quotes',
    PROCEED_BUY = '/health/proceed-buy-now',
    SEND_CHEAP_QUOTES  = '/health/send-cheap-quotes',
    GET_BENEFIT_MASTER = '/health/get-benefit-master',
    PROCEED_CHECKOUT = '/health/proceed-checkout',


    GET_REVIEW_DETAILS = '/health/get-review-details',
    SAVE_MEMBER_DETAILS = '/health/save-member-details'
}
