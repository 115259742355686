import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0f77082"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vechile-details-list" }
const _hoisted_2 = { class: "row form-block phone-ins-type-container" }
const _hoisted_3 = { class: "col-md-6" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "row form-block" }
const _hoisted_6 = { class: "col-md-6" }
const _hoisted_7 = { class: "col-md-6 counter-element" }
const _hoisted_8 = { class: "form-block members-info-travel" }
const _hoisted_9 = { class: "label-block" }
const _hoisted_10 = { class: "label-block_label" }
const _hoisted_11 = {
  class: "text-center",
  style: {"word-break":"keep-all"}
}
const _hoisted_12 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_input = _resolveComponent("h-input")!
  const _component_h_insured_type = _resolveComponent("h-insured-type")!
  const _component_h_member_form = _resolveComponent("h-member-form")!
  const _component_h_proceed = _resolveComponent("h-proceed")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form, {
      ref: "formPersonalDetails",
      model: _ctx.customer,
      "status-icon": "",
      rules: _ctx.rules,
      class: "demo-ruleForm"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_h_input, {
            isRequired: "",
            label: _ctx.$t('full_name'),
            isType: "text",
            "form-prop": "name",
            modelValue: _ctx.customer.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customer.name) = $event)),
            "max-length": "62",
            onkeydown: _ctx.lang === 'en' ? `return /[a-zA-Z_ \s'-]/i.test(event.key)` : '',
            "tooltip-message": _ctx.$t('full_name_tooltip'),
            ref: "name",
            placeHolder: _ctx.$t('full_name')
          }, null, 8, ["label", "modelValue", "onkeydown", "tooltip-message", "placeHolder"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_h_input, {
                isRequired: "",
                label: _ctx.$t('phone_number'),
                placeHolder: _ctx.$t('phone_number'),
                "is-type": "phone",
                "form-prop": "phone_number",
                "model-value": _ctx.customer.phone_number,
                "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.phone_number) = $event)),
                maxLength: _ctx.customer.phone_country_code == '+961' ? '8' : '10',
                "country-code": _ctx.customer.phone_country_code,
                "onUpdate:country-code": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.phone_country_code) = $event)),
                ref: "phone_number",
                onChange: _ctx.getExistLeads,
                disabled: _ctx.disablePhone
              }, null, 8, ["label", "placeHolder", "model-value", "maxLength", "country-code", "onChange", "disabled"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_h_insured_type, {
                isRequired: "",
                label: _ctx.$t('class'),
                "form-prop": "insured_type_id",
                ref: "insured_type_id",
                modelValue: _ctx.customer.insured_type_id,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.insured_type_id) = $event)),
                disabled: _ctx.disabledInsuredType
              }, null, 8, ["label", "modelValue", "disabled"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_h_input, {
                "is-required": "",
                label: _ctx.$t('email_address'),
                isType: "text",
                formProp: "email",
                placeHolder: _ctx.$t('email_address_placeholder'),
                modelValue: _ctx.customer.email,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customer.email) = $event)),
                maxLength: "62",
                ref: "email",
                onInput: _ctx.emailEvent
              }, null, 8, ["label", "placeHolder", "modelValue", "onInput"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_h_input, {
                isRequired: "",
                label: _ctx.$t('no_of_people'),
                isType: "step",
                min: 1,
                max: 8,
                formProp: "family_size",
                placeHolder: _ctx.$t('family_size'),
                modelValue: _ctx.customer.family_size,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.family_size) = $event)),
                ref: "family_size"
              }, null, 8, ["label", "placeHolder", "modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t("date_of_birth")) + " * ", 1)
              ])
            ]),
            (_ctx.showMembers)
              ? (_openBlock(), _createBlock(_component_h_member_form, {
                  key: 0,
                  customer: _ctx.customer,
                  members: _ctx.customer.members,
                  ref: "memberForm"
                }, null, 8, ["customer", "members"]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_h_proceed, {
            onHandleClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.personalDetails.click(_ctx.formPersonalDetails, _ctx.inst))),
            fullscreenLoading: _ctx.loading,
            disabled: ""
          }, null, 8, ["fullscreenLoading"])
        ])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.thankYouPopup.open,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.thankYouPopup.open) = $event)),
      title: _ctx.$t('thank_you'),
      width: "50%",
      center: "",
      style: {"padding-right":"0"}
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_12, [
          _createVNode(_component_el_button, { class: "mx-3" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            class: "btn-green",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.requestCallBack()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('request_call_back')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('thank_you_msg')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}