
  /* eslint-disable */
  import { defineComponent, ref, computed } from "vue";
  import { Health } from "@/core/types";
  import { FormInstance } from "element-plus";
  import { store } from "@/store";
  import { focus, dates as dDate } from "@/store/stateless"
  import moment from "moment";

  export default defineComponent({
    name: 'membersComponent',
    props: {
      members: {
        type: Array,
        required: true,
        default: []
      },
      customer: {
        type: Object,
        required: true,
        default: {} as Health,
      },
    },

    setup(_) {
      const formMemberDetails = ref < FormInstance > ();

      const editedMembers = computed(() => {
        return store.state.personals.customer.members
      })

      const language = computed(() => {
        return window.localStorage.getItem('language');
      })


      const defaultDob = computed(() => {
        return moment().format('YYYY-MM-DD')
      })

      const minYear = computed(() => {
        const currentYear = moment().year()
        const minYear = currentYear - 101
        return moment().year(minYear).format('YYYY-MM-DD')
      })

      const v_dob = (rule, values, callback) => {
        const obj = rule.field.split('.')[1]
        console.log('v_dov',editedMembers.value[obj].dob);
        if (editedMembers.value[obj].dob === 'Invalid date' || !editedMembers.value[obj].dob) {
          callback(new Error(language.value === 'ar' ? 'الرجاء تحديد تاريخ ميلاد صحيح' : 'Please select correct date of birth'));
        } else if (moment().diff(editedMembers.value[obj].dob, 'days') < 0) {
          callback(new Error(language.value === 'ar' ? 'الرجاء تحديد تاريخ ميلاد صحيح' : 'Please select correct date of birth'));
        } else {
          callback();
        }
      }

      return {
        focus,
        dDate,
        v_dob,
        defaultDob,
        formMemberDetails,
        minYear

      }
    }
  });
