import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { PersonalMutationTypes } from '@/core/enum/Personals/Mutations';
import { PersonalActionTypes } from '@/core/enum/Personals/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';
import { Health, Information } from '@/core/types';
import router from '@/router';
import moment from "moment";
import { store } from "@/store";
import { thankYouPopup, insuredFamilyValue, insuredParentValue, insuredDomesticWorkerValue } from "@/store/stateless"
import { isCalled } from '@/store/stateless';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [PersonalActionTypes.POST_PERSONAL_DETAILS](
    { commit }: AugmentedActionContext,
    payload: Health
  ): void;
  
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [PersonalActionTypes.POST_PERSONAL_DETAILS]({ commit }, payload) {
    isCalled.mainLoader = true
    try {

      const data = await axios.post(Links.POST_PERSONAL_DETAILS, payload);
      const personal_details = data.data.data.data;
      commit(PersonalMutationTypes.SET_CUSTOMER_RESULT, personal_details);

      const filtereCustomer:any = customer(personal_details.customer, payload, personal_details.health_lead_map_id);

      // store in session 
      window.sessionStorage.setItem('personal_details', JSON.stringify(personal_details));
      window.sessionStorage.setItem('session_id', personal_details.session_id);
      window.sessionStorage.setItem('pd', JSON.stringify(filtereCustomer));
      commit(PersonalMutationTypes.SET_CUSTOMER, filtereCustomer);
      
      window.sessionStorage.setItem('map_id', personal_details.health_lead_map_id)
      window.localStorage.setItem('personal_detail_submitted', JSON.stringify(payload));

      const params = {
        health_lead_map_id: personal_details.health_lead_map_id
      }
      await axios.post(Links.SEND_CHEAP_QUOTES, params);

      isCalled.mainLoader = false

      const routeName = 'CompareQuotes'
      const route = router.resolve({ name: routeName, query: { map_id: personal_details.health_lead_map_id } });
      window.location.href = route.href;
     

    }
    catch (err) {
      isCalled.mainLoader = false

      return err;

    }
  },

 
  async [PersonalActionTypes.GET_LATEST_LEADS] ({commit}, payload) {
    try {
      const data = await axios.post(Links.GET_LATEST_LEADS_BY_INSURANCE_ID, payload) 
      if(typeof data.data === 'object') {

        commit(PersonalMutationTypes.SET_CUSTOMER_EXISTING_LEADS, data.data)
        commit(PersonalMutationTypes.SET_CUSTOMER_EXISTING_SELECTED_LEADS, mapAllLeads(data.data))
        
        return true
      }
      commit(PersonalMutationTypes.SET_CUSTOMER_EXISTING_SELECTED_LEADS, [])
      commit(PersonalMutationTypes.SET_INSURED_TYPE_DISABLED, false)
      return false
    } catch (err) {
      return err
    }
  },

  async [PersonalActionTypes.GET_PERSONAL_DETAILS] ({commit}, payload) {
    try {
      const data = await axios.post(Links.GET_PERSONAL_DETAILS, payload) 
      
      return data.data.data.customer;
    } catch (err) {
      return err
    }
  },

  async [PersonalActionTypes.SET_CUSTOMER_LEADS]({ commit }, payload) {
    
    const health = setHealthObject(payload);
    commit(PersonalMutationTypes.SET_CUSTOMER, health)
  },

  async [PersonalActionTypes.GET_REVIEW_DETAILS] ({ commit }, payload) {
    try {
      const data = await axios.post(Links.GET_REVIEW_DETAILS, payload)       
      const personal_details = data.data.data.data;
      

      const health = setHealthObject(personal_details.customer);
      console.log('health',health)
      commit(PersonalMutationTypes.SET_CUSTOMER, health)

    } catch (err) {
      return err
    }
  },

  async [PersonalActionTypes.SAVE_MEMBER_DETAILS]({ commit }, payload) {
    // isCalled.mainLoader = true
    try {

      const data = await axios.post(Links.SAVE_MEMBER_DETAILS, payload);
      
     

    }
    catch (err) {
      // isCalled.mainLoader = false

      return err;

    }
  },




};

function  setHealthObject(payload){
  const { customer, insured_city, insured_type_id, insurance_type, leads, agent, additional, id, contact_person, is_declaration_submitted }  = payload
        const membersArray = members(leads);
        const health = {
          id: customer.id,
          name: customer.name,
          email: customer.email,
          contact_person,
          phone_number: customer.phone_number_without_code,
          phone_country_code: customer.phone_country_code,
          insured_city,
          insured_type_id,
          insurance_type,
          is_salary: additional && additional.is_salary === 1 ? true : false,
          expected_insurance_start_date: additional ? additional.expected_insurance_start_date : null,
          expected_premium: additional ? additional.expected_premium : null,
          customer_source: customer.customer_source,
          is_health: 1,
          utm_source: customer.utm_source,
          utm_medium: customer.utm_medium,
          utm_campaign: customer.utm_campaign,
          utm_content: customer.utm_content,
          dob: additional ? additional.dob : null,
          nationality_id: additional ? additional.nationality_id : 0,
          gender: additional ? additional.gender : null,
          is_married: additional && additional.is_married === 1 ? true : false,
          company_name: null,
          is_self: additional && additional.is_self === 1 ? true : false,
          agent: agent ? agent.id : null,
          health_lead_map_id: id,
          deleted_members:[],
          members: membersArray,
          family_size:membersArray.length,
          categories: [],
          is_declaration_submitted,
          is_web: true,
          hasDealInvoice: false,
          is_investor: payload.is_investor === 1 ? true : false
        }

        console.log('setting',health)

  return health;
}

function customer(data, payload, map_id) {
  const { customer,  insured_type_id, insurance_type, leads, agent, additional, id, is_declaration_submitted }  = data
      // const pd = await personalDetails(leads, customer.name)
      const query = new Map(Object.entries(payload.query))
      query.set('map_id', map_id);
      const sessionId = window.sessionStorage.getItem('session_id');
      const membersArray = leads && leads.length > 0 ? members(leads) : [];
      const health = {
        id: customer.id,
        lang: payload.lang,
        name: customer.name,
        email: customer.email,
        phone_number: customer.phone_number_without_code,
        phone_country_code: customer.phone_country_code,
        // insured_city,
        insured_type_id,
        insurance_type,
        // is_salary: additional && additional.is_salary === 1 ? true : false,
        expected_insurance_start_date: data ? data.expected_insurance_start_date : null,
        expected_premium: data ? data.expected_premium : null,

        customer_source: customer.customer_source,
        is_health: 1,
        utm_source: customer.utm_source,
        utm_medium: customer.utm_medium,
        utm_campaign: customer.utm_campaign,
        utm_content: customer.utm_content,
        age: payload.age,
        // dob: additional ? additional.dob : null,

        // nationality_id: additional ? additional.nationality_id : 0,
        // gender: additional ? additional.gender : null,
        // is_married: additional && additional.is_married === 1 ? true : false,
        // company_name: null,
        // is_self: additional && additional.is_self === 1 ? true : false,
        agent: agent ? agent.id : null,
        // is_company: payload.is_company,
        health_lead_map_id: id,
        deleted_members:[],
        members: membersArray,
        family_size: membersArray.length,
        // categories: [],
        query: Object.fromEntries(query),
        // is_declaration_submitted,
        is_web: true,
        hasDealInvoice: false,
        session_id:sessionId ?? null,
        // update_insurance_type:false,
        // is_investor:payload.is_investor
      }
    return health;
}

function members(leads) {
  return leads.map(x => {
    return x.lead_informations.map((y, index) => {
      return {
        id: y.id,
        lead_id: y.lead_id,
        member_name: y.is_temp === 0 ? y.member_name : null,
        relationship: y.is_temp === 0 ?  y.relationship : null,
        gender: y.is_temp === 0 ?  y.gender : null,
        age: y.is_temp === 0 ?  getAge(y.dob) : null,
        dob: y.is_temp === 0 ?  y.dob  : null, 
        nationality_id: y.is_temp === 0 ?  y.nationality_id : null,
        is_salary: y.is_temp === 0 ?  y.is_salary === 1 ? true :  false : false,
        // declaration_answers: y.is_temp === 0 ?  y.declaration_answers : [],
        is_married: y.is_temp === 0 ?  (y.is_married == 0 ? false : true ) : false,
        is_editable: x.lead_informations.length > 0 && index == 0 ? true : false,
        is_temp:y.is_temp

      }
    })
  }).flat()
}

function getAge(e: Date) {
    const today = moment().format('YYYY-MM-DD')
    return moment(today, 'YYYY-MM-DD').diff(moment(e, "YYYY-MM-DD"), 'years', true);
}

function mapAllLeads(data: any) {
  const value = data.map(x => x.insured_type_id)
  return [...new Set(value)]
}
