import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a3f3170"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row icon-date-picker1 apply-font travel-date mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_date_v2 = _resolveComponent("h-date-v2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-md-6 mt-5",
        key: index
      }, [
        _createVNode(_component_h_date_v2, {
          isRequired: "",
          rules: {validator: _ctx.v_dob, trigger: ['change', 'blur']},
          dayProp: `members.${index}.dob`,
          "form-prop": "dob",
          modelValue: member.dob,
          "onUpdate:modelValue": ($event: any) => ((member.dob) = $event),
          label: _ctx.$t('member_dob_label', { count: (index + 1) }),
          ref_for: true,
          ref: `members.${index}.dob`,
          defaultValue: _ctx.defaultDob,
          max: _ctx.dDate.yesterday,
          min: _ctx.minYear
        }, null, 8, ["rules", "dayProp", "modelValue", "onUpdate:modelValue", "label", "defaultValue", "max", "min"])
      ]))
    }), 128))
  ]))
}