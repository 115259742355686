
import { computed, defineComponent, onMounted} from "vue";
import { store } from "@/store";
import router from '@/router';
import personalDetails from "@/core/services/PersonalDetails";
import { healthDefault } from '@/store/stateless';
export default defineComponent({
  // eslint-disable-next-line
  setup(_) {

    const customer = computed(() => {
      // console.log('sddsfsfd',store.state.personals.customer)
      return store.state.personals.customer;
    });

    onMounted(async () => {
      
      // if(router.currentRoute.value && router.currentRoute.value.query && router.currentRoute.value.query.state && router.currentRoute.value.query.state == 'new') {
      //   // window.localStorage.clear()
      //   window.sessionStorage.clear()

      // }

      // customer.value.customer_source_url = router.currentRoute.value.fullPath;
      // customer.value.btm_source = router.currentRoute.value.query && router.currentRoute.value.query.btm_source ?  router.currentRoute.value.query.btm_source.toString() : '';
      customer.value.utm_source = router.currentRoute.value.query?.utm_source ?  router.currentRoute.value.query.utm_source.toString() : '';
      customer.value.utm_medium = router.currentRoute.value.query?.utm_medium ?  router.currentRoute.value.query.utm_medium.toString() : '';
      customer.value.utm_campaign = router.currentRoute.value.query?.utm_campaign ?  router.currentRoute.value.query.utm_campaign.toString() : '';
      customer.value.utm_content = router.currentRoute.value.query?.utm_content ?  router.currentRoute.value.query.utm_content.toString() : '';
      customer.value.insured_type_id = router.currentRoute.value.query?.insurance_type ? parseInt(router.currentRoute.value.query.insurance_type.toString()) : 0;
      
      
      
      const uuid = router.currentRoute.value.query ? router.currentRoute.value.query.uuid : null;
      if(uuid) {
        const payload = {
          uuid:uuid
        }
        const data = await personalDetails.getDetails(payload);
        // console.log(data);

          customer.value.name = data.customer.name;
          customer.value.phone_number = data.customer.phone_number_without_code;
          customer.value.phone_country_code = data.customer.phone_country_code;
      }
      const sid = router.currentRoute.value.query && router.currentRoute.value.query.sid ?  router.currentRoute.value.query.sid.toString() : '';
      if(sid != '')
      {
        window.sessionStorage.setItem('session_id',sid);
      }
      personalDetails.setCustomer(customer.value)

      if(uuid || customer.value.phone_number){
        //calling existing lead
        await personalDetails.getLatestLeads(customer.value)

      }

      
      
      // console.log('customer',customer.value )
    })

    return {
      customer,
    };
  },
});
