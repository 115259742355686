
  import { defineComponent, computed, onMounted, ref } from "vue";
  import { store } from "@/store";
  import router from "@/router";
  import Quotes from "@/core/services/Quotes";
  import { ElLoading } from "element-plus";
  import { useReCaptcha } from "vue-recaptcha-v3";
  import Insurances from "@/core/services/Insurances";
  import { isCalled } from '@/store/stateless';

  export default defineComponent({
    setup() {
      const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
      const folder = ref(process.env.VUE_APP_ASSETS_FOLDER);

      const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);
      const websiteUrl = computed(() => {
        return process.env.VUE_APP_PAYMENT_GATEWAY_URL
      })
      const readMore = ref(false);
      const captcha = useReCaptcha();

      const customer = computed(() => {
        return store.state.personals.customer;
      });

      const lang = computed(() => {
        const lang = window.localStorage.getItem("language");
        if (lang)
          return lang;

        return 'en';
      });

      const insurances = ref("you");

      const quote = computed(() => {
        const interested_policy =
          window.localStorage.getItem("interested_policy");
        if (interested_policy) {
          const data = JSON.parse(interested_policy);
          return data;
        } else {
          const route = router.resolve({ name: "PersonalDetails" });
          window.location.href = route.href;
          return null;
        }
      });

      const premiums = quote.value?.memberPremiums;
      const selectedPremium = ref(premiums?.[0]);
      const totalPremium = ref(0);
      const isAccept = ref(true);

      const vatper = computed(() => {
        return store.state.quotes.vat;
      });
      const vat = computed(() => {
        if (vatper.value > 0) {
          return selectedPremium.value.totalPremium * (vatper.value / 100);
        }

        return 0;
      });

     

      const changeCopay = (index) => {
        selectedPremium.value = premiums[index];
        calculateTotalPremium();
      };

      const calculateTotalPremium = () => {
        totalPremium.value =
          selectedPremium.value.totalPremium +
          vat.value 
      };

      onMounted(async () => {
        await Quotes.getVat();

        // console.log('insurances',insuredTypes[0]?.label)

        const selected_premium = window.localStorage.getItem("selected_premium");
        if (selected_premium) {
          const data = JSON.parse(selected_premium);
          selectedPremium.value = data;
        }

        calculateTotalPremium();
      });

      const proceedClick = async () => {
        console.log("buy");
       
        isCalled.mainLoader = true
        await captcha?.recaptchaLoaded();
        // execute reCaptcha
        const token = await captcha?.executeRecaptcha("login");
        
        // eslint-disable-next-line
        const map_id: any = window.sessionStorage.getItem("map_id");
        const payload = {
          language: lang.value,
          url: websiteUrl.value,
          health_lead_map_id: map_id,
          selected_premium: selectedPremium.value,
          policy_id: quote.value.id,
        };
        if (token) {
          await Quotes.proceedCheckout(payload);
        }

        // isCalled.mainLoader = false

        // const route = router.resolve({ name: "CallBack" });
        // window.location.href = route.href;
      };

      return {
        domain,
        url,
        quote,
        selectedPremium,
        premiums,
        customer,
        totalPremium,
        vat,
        vatper,
        readMore,
        changeCopay,
        proceedClick,
        isAccept,
        insurances,
        lang,
        folder,
        websiteUrl
      };
    },
  });
