
import { computed, defineComponent, ref, watchEffect } from 'vue'
import { store } from '@/store'
import personalDetails from '@/core/services/PersonalDetails';
import { formPersonalDetails, screen } from "@/store/stateless";

export default defineComponent({
    setup() {
        const open = ref(false);

        const existingLeads = computed(() => {
            return store.getters.getAllExistingLeads
        })

        watchEffect(() => {
            if(existingLeads.value.length > 0) open.value = true
        })

        const setDetails = (value) => {
            const find = store.state.personals.existingLeads.find(x => x.id === value.map_lead_id)
            console.log(find);
            personalDetails.setCustomerLeads(find)
            personalDetails.setInsuredTypeDisabled(true)
            personalDetails.setExistClick(true)
            open.value = false
        }

        const newDetails = () => {
            let find = store.state.personals.existingLeads[0];
            // if(!find)
            //    find = store.state.personals.customer;

            const { customer,insured_city, additional }  = find
            personalDetails.setCustomer({
                id: store.state.personals.customer.id,
                lang: store.state.personals.customer.lang,
                name: store.state.personals.customer.name,
                email: store.state.personals.customer.email,
                phone_number: store.state.personals.customer.phone_number,
                phone_country_code: store.state.personals.customer.phone_country_code,
                insured_city: insured_city,
                insured_type_id: 0,
                insurance_type: 1,
                is_salary: store.state.personals.customer.is_salary,
                expected_insurance_start_date: null,
                expected_premium: null,

                expected_insurance_start_day: "",
                expected_insurance_start_month: "",
                expected_insurance_start_year: "",
                customer_source: null,
                is_health: 1,
                utm_source: null,
                utm_medium: null,
                utm_campaign: null,
                utm_content: null,
                age: 0,
                dob: additional ? additional.dob : null,
                dob_day: "",
                dob_month: "",
                dob_year: "",
                nationality_id: additional ? additional.nationality_id : 0,
                gender: additional ? additional.gender : null,
                is_married: false,
                company_name: null,
                is_self: null,
                agent: null,
                health_lead_map_id: 0,
                is_company: false,
                family_size:1,
                members: [{
                    id:  null,
                    lead_id: null,
                    age: 0,
                    dob: null,
                    is_temp: 0,
                    temp_record: 0
                }],
                categories: [],
                query: {},
                is_web: true
            })
            formPersonalDetails.value?.resetFields()
            open.value = false
            personalDetails.setInsuredTypeDisabled(false)
            personalDetails.setExistClick(true)

        }

        return {
            formPersonalDetails,
            existingLeads,
            screen,
            open,
            setDetails,
            newDetails,
        }
    },
})
