import PersonalDetails from '@//views/PersonalDetails.vue';
import HealthDeclaration from '@/views/HealthDeclaration.vue';
import CompareQuotes from '@/views/CompareQuotes.vue';
import CompareOffer from '@/views/CompareOffer.vue';
import ThankYou from '@/views/ThankYou.vue';
import CallbackThankYou from '@/views/CallbackThankYou.vue';
import CheckoutDetails from '@/views/CheckoutDetails.vue';
import ReviewDetails from '@/views/ReviewDetails.vue';


export const routes = [
    {
        path: '/personal-details',
        name: 'PersonalDetails',
        component: PersonalDetails,
        header_id: 1
    },
    // {
    //     path: '/health-declarations',
    //     name: 'HealthDeclaration',
    //     component: HealthDeclaration,
    //     header_id: 2
    // },
    {
        path: '/compare-quotes',
        name: 'CompareQuotes',
        component: CompareQuotes,
        header_id: 2
    },
    {
        path: '/compare-offers',
        name: 'CompareOffer',
        component: CompareOffer,
        header_id: 3
    },
    {
        path: '/review-details',
        name: 'ReviewDetails',
        component: ReviewDetails,
        header_id: 4
    },
    {
        path: '/checkout',
        name: 'CheckoutDetails',
        component: CheckoutDetails,
        header_id: 5
    },
    {
        path: '/thank-you',
        name: 'ThankYou',
        component: ThankYou,
        header_id: 5
    },
    {
        path: '/thankyou',
        name: 'CallBack',
        component: CallbackThankYou,
        header_id: 6
    }
]

