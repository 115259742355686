import moment from "moment/moment";
import { reactive,ref } from "vue";


const focus = reactive({
  personal: '',
  member: ''
});

const emirates = reactive({
  abhu_dhabi: 0, 
  ajman: 1, 
  dubai: 2, 
  fujairah: 3, 
  ras_al_khaimah: 4, 
  sharjah: 5, 
  umm_al_quwain: 6, 
})

const insurance_for = reactive({
  myself: 1,
  domestic_worker: 2,
  family: 3,
  employee: 4,
  parents: 5
})

const insuredFamilyValue = 3

const insuredParentValue = 5

const insuredDomesticWorkerValue = 2

const screen = reactive({
  width: window.screen.width
})

const thankYouPopup = reactive({
  open : false
})

const dates = reactive({
  min: moment().subtract(17,'y').format('YYYY-MM-DD'),
  today: moment().format('YYYY-MM-DD'),
  yesterday: moment().subtract(1,'d').format('YYYY-MM-DD'),
  expected_date: moment().add(6, 'M').format('YYYY-MM-DD')
})

const loading = ref(false)
const svgLoading = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `

const healthDefault = ref({
  id: null,
  lang: window.localStorage.getItem('language'),
  name: null,
  email: null,
  phone_number: null,
  phone_country_code: "+961",
  insured_city: null,
  insured_type_id: 0,
  insurance_type: 2,
  is_salary: true,
  expected_insurance_start_date: null,
  expected_premium:null,
  customer_source: null,
  is_health: 1,
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_content: null,
  age: 0,
  dob: null,
  nationality_id: null,
  family_size:1,
  gender: null,
  is_married: false,
  company_name: null,
  is_self: null,
  agent: null,
  health_lead_map_id: 0,
  deleted_members:[],
  members: [{
    id: null,
    lead_id: null,
    member_name: null,
    relationship: null,
    gender: null,
    age: 0,
    dob: null,
    nationality_id: null,
    is_salary: true,
    // declaration_answers: [],
    is_married: false,
    is_editable: true,
    is_temp:0,
    temp_record:0
  }],
  
  query: {},
  is_web: true,
  session_id:null,
})

export { focus, emirates, insurance_for, dates, screen, thankYouPopup, insuredFamilyValue , insuredParentValue, insuredDomesticWorkerValue, loading, svgLoading, healthDefault };