import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import skeletonDetails from '@/App.vue';
import { routes as routeChild} from '@/core/utils'

const host = window.location.host;
console.log(host,process.env.VUE_APP_HEALTH_DOMAIN)
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/personal-details',
    component: skeletonDetails,
    children: routeChild
  },
  {
      path: '/:pathMatch(.*)*',
      name:'PersonalDetailsAl',
      redirect:'/personal-details'
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return scrollTo({ top: 0 })
  }
})

export default router
