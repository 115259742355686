
import { computed, defineComponent, ref, toRef, watch } from 'vue';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { currency } from '@/core/functions/currency';
import Quotes from '@/core/services/Quotes';
import router from '@/router';
import { store } from '@/store';
import PersonalDetails from '@/core/services/PersonalDetails';
import { isCalled } from '@/store/stateless';

export default defineComponent({
  props: {
    quote: {
      type: Object,
      required: true,
    },
    expand: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(props) {
    const captcha = useReCaptcha();
    const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
    const folder = ref(process.env.VUE_APP_ASSETS_FOLDER);
    const quote = toRef(props, 'quote');

    const copaySelection = computed(() => store.state.quotes.copay_selection);
    const moreDetails = ref(false);
    const premiums = computed(() => quote.value.memberPremiums);
    const selectedPremium = ref(premiums.value[0]);

    watch(() => props.quote, () => changeCopay(0));

    const changeCopay = (index) => {
      selectedPremium.value = premiums.value[index];
      const key = copaySelection.value.findIndex((item) => item.id === quote.value.id);

      if (key === -1 && copaySelection.value.length < 3) {
        copaySelection.value.push({ id: quote.value.id, selectedPremium: selectedPremium.value });
      } else {
        copaySelection.value.splice(key, 1, { id: quote.value.id, selectedPremium: selectedPremium.value });
      }

      window.sessionStorage.setItem('copaySelection', JSON.stringify(copaySelection.value));
    };

    const lang = computed(() => window.localStorage.getItem('language') || 'en');
    const compareList = computed(() => store.state.quotes.compare_list);
    const customer = computed(() => store.state.personals.customer);

    const BuyNow = async () => {
      isCalled.mainLoader = true;
      await captcha?.recaptchaLoaded();
      const token = await captcha?.executeRecaptcha('login');
      const map_id = window.sessionStorage.getItem("map_id");
      const payload = {
        health_lead_map_id: map_id,
        selected_premium: selectedPremium.value,
        policy_id: quote.value.id,
      };

      if (token) {
        // if (customer.value.insured_type_id === 3 || customer.value.insured_type_id === 5) {
        //   customer.value.insurance_type = quote.value.insurance_type;
        //   await PersonalDetails.post(customer.value);
        // }

        window.localStorage.setItem("member_index", JSON.stringify(0));


        await Quotes.buyNow(payload);
      }

      isCalled.mainLoader = false;
      const route = router.resolve({ name: 'ReviewDetails' });
      window.location.href = route.href;
    };

    return {
      premiums,
      selectedPremium,
      changeCopay,
      domain,
      folder,
      BuyNow,
      compareList,
      lang,
      moreDetails,
    };
  },
});
