/* eslint-disable */
import { store } from "@/store";
import { App } from "vue";
import { PersonalActionTypes } from "@/core/enum/Personals/Actions";
import { PersonalMutationTypes } from "@/core/enum/Personals/Mutations";
import { Result, Generics } from '@/core/types';

class personalDetails{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        personalDetails.vueInstance = app;
    }

    public static async post(payload) {
        const sessionId = window.sessionStorage.getItem('session_id');
        if(sessionId)
            payload.session_id = sessionId; // storing current session

        return await store
        .dispatch(PersonalActionTypes.POST_PERSONAL_DETAILS, payload);
    }


    public static async getLatestLeads(payload) {
        return await store.dispatch(PersonalActionTypes.GET_LATEST_LEADS, payload);
    }

    public static async getDetails(payload) {
        return await store.dispatch(PersonalActionTypes.GET_PERSONAL_DETAILS, payload);
    }

    public static setCustomer(payload: any) {
        store.commit(PersonalMutationTypes.SET_CUSTOMER, payload);
    }

    public static setResult(payload: Result) {
        store.commit(PersonalMutationTypes.SET_CUSTOMER_RESULT, payload);
    }

    public static setRelationships(value) {
        if(value) {
            store.state.relationships.families.push({
                value: 15, label: "Sponsor"
            });
        } 
    }

    public static setMembers(payload: Array<Generics>) {
        store.commit(PersonalMutationTypes.SET_CUSTOMER_MEMBERS, payload);
    }

    public static setAges(payload: Array<number>) {
        store.commit(PersonalMutationTypes.SET_CUSTOMER_AGES, payload);
    }

    public static setLoading(payload: boolean) {
        store.commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, payload);
    }

    public static setCustomerLeads(payload: boolean) {
        store.dispatch(PersonalActionTypes.SET_CUSTOMER_LEADS, payload);
    }

    public static setInsuredTypeDisabled(payload: boolean) {
        store.commit(PersonalMutationTypes.SET_INSURED_TYPE_DISABLED, payload);
    }

    public static setExistClick(payload: boolean) {
        store.commit(PersonalMutationTypes.SET_EXIST_CLICK, payload);
    }

    public static async getReviewDetails(payload) {
        return await store.dispatch(PersonalActionTypes.GET_REVIEW_DETAILS, payload);
    }

    public static async saveMemberDetails(payload) {
        return await store.dispatch(PersonalActionTypes.SAVE_MEMBER_DETAILS, payload);
    }

}

export default personalDetails;