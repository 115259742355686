const ar = {
  test: "English",
  personal: "Personal",
  details: "Details",
  declaration: "Declaration",
  compare: "Compare",
  quotes: "Quotes",
  purchase: "Purchase",
  insurance: "Insurance",
  personal_details: "Personal Details",
  will_take: "This will only take 1 minute!",
  full_name: "Your Full Name",
  full_name_tooltip: "this is a test tooltip english",
  phone_number: "Phone Number",
  insured_type: "Insured Type",
  city: "Visa Issued In (Emirates)",
  city_tooltip: "City",
  date_of_birth: "Date of Birth",
  date_of_birth_tooltip: "Date of Birth",
  day: "Day",
  month: "Month",
  year: "Year",
  nationality: "Nationality",
  yes: "Yes",
  no: "No",
  salary: "Is your salary more than AED {salary} per month?",
  expected_insurance: "Expected Insurance Start Date",
  expected_insurance_tooltip: "Expected Insurance Start Date",
  email_address: "Email Address",
  email_address_placeholder: "Email address",
  email_address_tooltip: "Email address",
  gender: 'Gender',
  member_date_of_birth: "Member's date of birth",
  relationship: 'Relationship',
  member_full_name: 'Full Name',
  member_details: 'Enter member details',
  company_name: 'Company Name',
  employee_full_name: 'Full Name',
  company_contact_number: 'Contact Number',
  company_email_address: 'Company Email Address',
  company_no_members: 'No. Of Members',
  add_member: 'Add member',
  add_another_member: 'Add another member',
  save_edited_member: 'Save member',
  member: 'Member',
  edit_details: 'Edit details',
  delete: 'Delete',
  name: 'Name',
  salary_more_than: 'Salary > AED 4,000',
  thank_you: 'Thank You !!!',
  some_extra_information: 'Some extra information is required',
  declaration_que_ans: 'Due to your declaration answers, we will require additional information in order to generate quotes. <br/>We will call you shortly to finish the process.',
  no_declaration_que_ans: 'We will require additional information in order to generate quotes. <br/>We will call you shortly to finish the process.',
  alternatively_call_us: 'Alternatively, call us at <a href="tel:01481565">01 481 565</a> for immediate assistance.',
  is_married: 'Is Married?',
  is_self: 'Do you need insurance for yourself?',
  all: 'All',
  basic: 'Basic',
  enhanced: 'Enhanced',

  get_insured: "Get Insured",
  car_insurance: "Car Insurance",
  health_insurance: "Health Insurance",
  home_insurance: "Home Insurance",
  bike_insurance: "Bike Insurance",
  other_insurance: "Other Insurance",
  renew_a_policy: "Renew a Policy",
  company: "Company",
  about: "About",
  insurance_partners: "Insurance Partners",
  hr_solutions: "HR Solutions",
  blog: "Blog",
  quick_links: "Quick links",
  privacy_page: "Privacy Page",
  tac: "Terms & Conditions",
  ami: "Al Manarah insurance",
  edarat: "E-DARAT",
  support: "Support",
  help_center: "Help Center",
  contact: "Contact",
  cancellation: "Cancellation",
  calculator: "Calculators",
  car_loan: "Car Loan",
  mortgage: "Mortgage",
  insurance_calc: "Insurance Calc",
  mail: "Email",
  head_office: "Head Office",
  need_assistance: "NEED ASSISTANCE",
  call_us: "or Call us on",
  support_center: "Visit Support Center",
  corporate: "Corporate",
  solution: "Solutions",
  address: 'Al Bacha Gardens Center, Block B, 1st floor, Jisr el Bacha, Lebanon',
  disclaimer: 'Disclaimers',
  disclaimer_text: 'Premium Choice Marketing LLC office 1406, Damac Smart Heights, Barsha Heights, Dubai, UAE is the owner of the brand name “ BuyAnyInsurance.com/ae". Insurance is provided by North Star Insurance Services with License No. 227 of the Insurance Authority.',
  company_of: 'A company of',
  proceed: "Proceed",
  wait: "Please wait...",
  corporate_solution: 'Corporate Solutions',
  thank_you_msg : 'If you need to view quotes kindly click Cancel & enter the member details on the Personal details screen. If you want a callback kindly click the Request a Call button.',
  cancel : 'Cancel',
  request_call_back:'Request a Call',
  callback_thank_you_msg: 'Thank you for submitting a request . our team will contact you soon !',
  no_quotes_manual_quote_title: 'For a manual quotation, please contact us on 800 765 429 or ',
  go_back: 'Go back to Homepage',
  AED: "AED",
  annual_limit: 'Annual Limit',
  scope_coverage: 'Scope of Coverage',
  network_list: 'Network list',
  maternity_coverage: 'Maternity Coverage',
  dental:'Dental',
  optical:'Optical',
  deductibles: 'Deductibles',
  copay:'Co-Pay',
  total_premium:'Total Premium',
  buy_now:'Buy now',
  member_premium:'Member Premium',
  add_compare:'Add to compare',
  show_more:'Show more',
  show_less:'Show less',
  price_vat: 'The prices you see here are exclusive of {vat}% VAT',
  quotes_count: 'We have found {count} health insurance quotes for you',
  show_all_btn: 'Show All ({count}) available in {policy}',
  show_less_btn: 'Show Less available in {policy}',
  we_cannot_process: 'We cannot process your application online. Our team will contact you shortly.',
  no_quotes_point_one: 'Your rquirements falls under a specific that insurance companies would like to know more about',
  secure_checkout: "Secure Checkout",
  show_full_summary: 'Show Full Summary',
  full_summary: 'Full Summary',
  hide_full_summary: 'Hide Full Summary',
  summary: "Summary",
  premium: "Premium",
  vat: "VAT",
  vat_t: "VAT {vat} %",
  total_amount: "Total Amount",
  basmah: 'Basmah',
  agree: 'Agree to the',
  download: 'Download',
  payable_amount: 'Payable Total',
  no_quotes_found : 'No Quotes Found !!',
  compare_offer: 'Compare Offer',
  compare_plan: 'Compare Plans',
  back : 'Back',
  expected_premium : 'Expected Premium',
  expected_premium_placeholder : 'Enter expected premium',
  premium_statement : 'Premium is indicative subject to declaration premium may change',
  premium_statement1 : 'Premium is indicative',
  copyrights:'Copyrights',
  copyright_text: ' Insurance is the subject matter of solicitation.',
  is_investor: 'Are you an Investor ?',
  select_existing_lead : 'Select from existing entered data',
  enter_new_details: 'Enter New Details',

  validation_email_1: "Please enter email",
  validation_email_2: "Email address must be valid",
  validation_email_3: "Email already taken",
  validation_names: "Please enter your full name",
  validation_company_name: "Please enter your company name",
  validation_number_members_1: "Please enter number of members",
  validation_number_members_2: "Member count must not be less than or equal to ",
  validation_member_name: "Please enter member full name",
  validation_phone_number_1: "Please enter phone number",
  validation_phone_number_2: "Phone number already taken",
  validation_phone_number_3: "Phone number must not be less than 8 digits",
  validation_insured_type: "Please select insured type",
  validation_emirate: "Please select city",
  validation_dob_1: "Please enter date of birth",
  validation_dob_2: "You still don't exist",
  validation_insurance_date: "Please select correct date",
  validation_day: "Please select date",
  validation_month: "Please select month",
  validation_year: "Please select year",
  validation_nationality: "Please select nationality",
  validation_gender: "Please select gender",
  validation_relationship: "Please select relationship",
  incl_vat: "( incl. vat)",
  your_cover: 'Your Cover',
  faqs: "FAQs",

  corporate_info: "Group Details",
  company_email: "Company Email",
  company_email_placeholder: "Company Email Address",
  company_name_placeholder: "Company Name",
  contact_number: "Contact Number",
  contact_number_placeholder: "Contact Number",
  contact_person: "Contact Person",
  contact_person_placeholder: "Contact Person",
  no_of_members: "No. of Members/group size",
  no_of_members_placeholder: "No. of Members/group size",
  thank_you_group: 'Thank You',
  callback_thank_you_msg_group: 'Thank you for your enquiry. Our team will contact you shortly.',
  go_back_home: 'Go back to home page',
  family_size:'Family Size',
  member_dob_label: "Member {count}",
  class: "Class",
  outpatient: "Outpatient",
  USD:'$',
  no_of_people: 'No. of People to Insure',
  review_details: 'Review Details',
  review: 'Review',
  copyrights_footer: 'Insurance is the subject matter of solicitation. © Copyright-DATE_FOOTER BuyAnyInsurance.com.',

  expat_insurance: 'Expat Insurance',
  expat_health_insurance:'Expat Health Insurance',
  pet_insurance: 'Pet Insurance',
  google_map: 'Google Map',
  travel_insurance: 'Travel Insurance',
  policy_for_you: 'Policy for you'

};

export default ar;
